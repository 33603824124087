import React, { createContext, useContext, useState } from "react";

const HistoryContext = createContext();

export const HistoryProvider = ({ children }) => {
  const [historyStack, setHistoryStack] = useState([]);

  const pushRoute = (route) => {
    setHistoryStack((prev) => [...prev, route]);
  };

  return (
    <HistoryContext.Provider value={{ historyStack, pushRoute }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryStack = () => useContext(HistoryContext);
