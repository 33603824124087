import { Region, SeatsioClient } from "seatsio";
import axios from 'axios'

const extractParentType = ({ parent }) => {
    if (!parent?.type) {
        return ''
    }

    if (parent.type.toLowerCase() === 'table') {
        return 'שולחן'
    }
    if (parent.type.toLowerCase() === 'row') {
        return 'שורה'
    }
    return ''
}

const extractSeatIoData = (seatIoTicket) => {
    switch (seatIoTicket.objectType) {
        case 'GeneralAdmissionArea':
        case 'generalAdmission':
            return { labelOne: seatIoTicket.labels.own?.label, labelTwo: '', amount: seatIoTicket.numSelected }
        case 'Seat':
        case 'seat':
            const parentNumber = seatIoTicket.labels?.parent?.label?.match(/\d+/g).join('')
            const parentType = extractParentType(seatIoTicket.labels)
            return { labelOne: `כיסא ${seatIoTicket.labels?.own?.label}`, labelTwo: `${parentType} ${parentNumber}` }
    }
}

export const SeatsIoService = {
    async getRelevantCharts(workspaceSecretKey) {
        // TODO - might need pagination in the future
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        const allCharts = await client.charts.listFirstPage()
        return allCharts.items
    },
    async validateChart(chartKey, workspaceSecretKey) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        return client.charts.validatePublishedVersion(chartKey)
    },
    // async createEvent(chartKey, eventKey) {
    //     const client = new SeatsioClient(Region.EU(), secretKey)
    //     return client.events.create(chartKey, eventKey);
    // },
    async getEvent(eventKey, workspaceSecretKey) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        return client.events.retrieve(eventKey);
    },
    async updateEvent(eventKey, workspaceSecretKey, eventData) {
        const url = `https://api-eu.seatsio.net/events/${eventKey}`;
        // encode the secret key
        const encodedSecretKey =  btoa(workspaceSecretKey+':');
        const headers = {
            'Authorization': `Basic ${encodedSecretKey}`,
        }
        return axios.post(url, eventData, { headers })
    },
    async getChart(chartKey, workspaceSecretKey) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        return client.charts.retrieve(chartKey)
    },
    async getEventReport(eventKey, workspaceSecretKey) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        return client.eventReports.byCategoryKey(eventKey);
    },
    async getAvailableSeatsCertainCategory(eventKey, workspaceSecretKey, categoryLabel) {
        const seatsList = []
        const seatsMap = {}
        const eventReport = await SeatsIoService.getEventReport(eventKey, workspaceSecretKey)
        if (eventReport) {
            const keys = Object.keys(eventReport)
            for (const key of keys) {
                const list = eventReport[key]
                for (const seatCategory of list) {
                    if (seatCategory?.status === 'free') {
                        seatCategory.nimiLabels = extractSeatIoData(seatCategory)
                        if (categoryLabel) {
                            if (seatCategory.categoryLabel === categoryLabel) {
                                seatsMap[seatCategory.label] = seatCategory
                                seatsList.push(seatCategory.label)
                            }
                        } else {
                            seatsMap[seatCategory.label] = seatCategory
                            seatsList.push(seatCategory.label)
                        }
                    }
                }
            }
            return { seatsList, seatsMap }
        }
    },
    async bookSeats(eventKey, workspaceSecretKey, seats) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        await client.events.book(eventKey, seats) // Example - ['A-3', 'A-5'] || 'A-5'
    },
    async releaseSeats(eventKey, workspaceSecretKey, seats) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        await client.events.release(eventKey, seats) // Example - ['A-3', 'A-5'] || 'A-5'
    },
    async holdSeats(eventKey, workspaceSecretKey, seats) {
        const client = new SeatsioClient(Region.EU(), workspaceSecretKey)
        const holdToken = await client.holdTokens.create();
        await client.events.hold(eventKey, seats, holdToken.holdToken);
    },
    async switchSeats(eventKey, workspaceSecretKey, seatsToRelease, seatsToBook) {
        await SeatsIoService.bookSeats(eventKey, workspaceSecretKey, seatsToBook)
        await SeatsIoService.releaseSeats(eventKey, workspaceSecretKey, seatsToRelease)
    },
    async switchSeatsByHold(eventKey, workspaceSecretKey, seatsToRelease, seatsToHold) {
        await SeatsIoService.holdSeats(eventKey, workspaceSecretKey, seatsToHold)
        await SeatsIoService.releaseSeats(eventKey, workspaceSecretKey, seatsToRelease)
    }


}
