import myKingLogo from "../assets/mykind_Logo.png";
import easytixLogo from "../assets/easytix_Logo.jpg";
import hormesisLogo from "../assets/hormesis_logo.png";
import radicalLogo from "../assets/radical-logo-2.svg";
import zoaLogo from "../assets/zoa_logo.jpeg"
import minkovskyLogo from "../assets/minkovsky.png"
import comedyClubLogo from "../assets/ccclogo.png"
import nimiLogo from "../assets/logo.png"

import { Loader } from "../components/minimal/loading-screen/Loader";
import { MyKindLoader } from "../components/my-kind/Logo";
import { EasyTixLoader } from "../components/easytix/Logo";
import { HormesisLoader } from "../components/hormesis/Logo.jsx";
import { RadicalLoader } from "../components/radical/Logo.jsx";
import { ZoaLoader } from "../components/zoa/Logo.jsx";
import { MinkovskyLoader } from "../components/minkovsky/Logo.jsx";
import { ComedyClubLoader } from "../components/comedy-club/Logo.jsx";
import DefaultTermsOfService from '../assets/docs/nimi-terms-of-services.pdf'
import DefaultPrivacyPolicy from '../assets/docs/nimi-privacy-policy.pdf'

export { easytixLogo, myKingLogo, hormesisLogo, radicalLogo, zoaLogo, minkovskyLogo, comedyClubLogo }

export const isMyKind = () => {
    return window.location.host.includes('mykind') || window.location.search?.includes('mykind')
}
export const isEasyTix = () => {
    return window.location.host.includes('easytix') || window.location.search?.includes('easytix')
}
export const isHormesis = () => {
    return window.location.host.includes('hormesis') || window.location.search?.includes('hormesis')
}
export const isRadical = () => {
    return window.location.host.includes('radical') || window.location.search?.includes('radical')
}
export const isZoa = () => {
    return window.location.host.includes('zoa') || window.location.search?.includes('zoa')
}
export const isMinkovsky = () => {
    return window.location.host.includes('minkovsky') || window.location.search?.includes('minkovsky')
}
export const isComedyClub = () => {
    return window.location.host.includes('comedyclub') || window.location.search?.includes('comedyclub')
}
export const isNimi = () => {
    return window.location.host.includes('nimi') || window.location.search?.includes('nimi') || window.location.host.includes('localhost')
}

export const MarketplaceName = Object.freeze({
    NIMI: "NIMI",
    MYKIND: "MYKIND",
    EASYTIX: "EASYTIX",
    HORMESIS: "HORMESIS",
    RADICAL: "RADICAL",
    ZOA: "ZOA",
    MINKOVSKY: "MINKOVSKY",
    COMEDYCLUB: "COMEDYCLUB",
})

export const MarketplaceBasePath = Object.freeze({
    NIMI: "/www.nimi.co.il",
    MYKIND: "/www.mykind.cool",
    EASYTIX: "/www.easytix.co.il",
    HORMESIS: "/tickets.hormesis.club",
    RADICAL: "/crm.radical.org.il/",
    ZOA: "/tickets.zoatlv.co.il",
    MINKOVSKY: "/tickets.minkovsky-records.com/",
    COMEDYCLUB: "/admin.comedyclub.co.il"
})

export const MarketplaceEventPath = Object.freeze({
    NIMI: "/www.nimi.co.il/event",
    MYKIND: "/www.mykind.cool/event",
    EASYTIX: "/www.easytix.co.il/event",
    HORMESIS: "/tickets.hormesis.club/event",
    RADICAL: "/crm.radical.org.il/event",
    ZOA: "/tickets.zoatlv.co.il",
    MINKOVSKY: "/tickets.minkovsky-records.com/",
    COMEDYCLUB: "/admin.comedyclub.co.il/event"
})

export const getMarketPlaceName = () => {
    if (isMyKind()) {
        return MarketplaceName.MYKIND
    }
    if (isEasyTix()) {
        return MarketplaceName.EASYTIX
    }
    if (isHormesis()) {
        return MarketplaceName.HORMESIS
    }
    if (isRadical()) {
        return MarketplaceName.RADICAL
    }
    if (isZoa()) {
        return MarketplaceName.ZOA
    }
    if (isMinkovsky()) {
        return MarketplaceName.MINKOVSKY
    }
    if (isComedyClub()) {
        return MarketplaceName.COMEDYCLUB
    }
    return MarketplaceName.NIMI
}

export const getMarketPlaceEventPath = () => {
    if (isMyKind()) {
        return MarketplaceEventPath.MYKIND
    }
    if (isEasyTix()) {
        return MarketplaceEventPath.EASYTIX
    }
    if (isHormesis()) {
        return MarketplaceEventPath.HORMESIS
    }
    if (isRadical()) {
        return MarketplaceEventPath.RADICAL
    }
    if (isZoa()) {
        return MarketplaceEventPath.ZOA
    }
    if (isMinkovsky()) {
        return MarketplaceEventPath.MINKOVSKY
    }
    if (isComedyClub()) {
        return MarketplaceEventPath.COMEDYCLUB
    }
    return MarketplaceEventPath.NIMI
}

export const getMarketPlaceSellerPublicPath = () => {
    if (isMyKind()) {
        return MarketplaceBasePath.MYKIND
    }
    if (isEasyTix()) {
        return MarketplaceBasePath.EASYTIX
    }
    if (isHormesis()) {
        return MarketplaceBasePath.HORMESIS
    }
    if (isRadical()) {
        return MarketplaceBasePath.RADICAL
    }
    if (isZoa()) {
        return MarketplaceBasePath.ZOA
    }
    if (isMinkovsky()) {
        return MarketplaceBasePath.MINKOVSKY
    }
    if (isComedyClub()) {
        return MarketplaceBasePath.COMEDYCLUB
    }
    return MarketplaceBasePath.NIMI
}


export const getMarketplaceLogo = () => {
    if (isMyKind()) {
        return { path: myKingLogo, width: "40px", height: "40px" }
    }
    if (isEasyTix()) {
        return { path: easytixLogo, width: "100px", height: "40px" }
    }
    if (isHormesis()) {
        return { path: hormesisLogo, width: "100px", height: "25px" }
    }
    if (isRadical()) {
        return { path: radicalLogo, width: "auto", height: "25px" }
    }
    if (isZoa()) {
        return { path: zoaLogo, width: "100px", height: "45px" }
    }
    if (isMinkovsky()) {
        return { path: minkovskyLogo, width: "100px", height: "45px" }
    }
    if (isComedyClub()) {
        return { path: comedyClubLogo, width: "120px", height: "60px" }
    }
    if (isNimi()) {
        return { path: nimiLogo, width: "auto", height: "55px" }
    }
    return null
}

export const getMarketplaceLoader = () => {
    if (isMyKind()) {
        return MyKindLoader
    }
    if (isEasyTix()) {
        return EasyTixLoader
    }
    if (isHormesis()) {
        return HormesisLoader
    }
    if (isRadical()) {
        return RadicalLoader
    }
    if (isZoa()) {
        return ZoaLoader
    }
    if (isMinkovsky()) {
        return MinkovskyLoader
    }
    if (isComedyClub()) {
        return ComedyClubLoader
    }
    return Loader
}

export const getMarketPlaceTermsOfServiceAndPp = () => {
    if (isMyKind()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    if (isEasyTix()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    if (isHormesis()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    if (isRadical()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    if (isZoa()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    if (isMinkovsky()) {
        return [DefaultTermsOfService, DefaultPrivacyPolicy]
    }
    return [DefaultTermsOfService, DefaultPrivacyPolicy]
}


export const getPageTitleByDomain = (title = "") => {
    if (title) {
        return `${title} | ${getMarketPlaceName()}`
    }
    return getMarketPlaceName()
}
