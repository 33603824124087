// i18n
import './locales/i18n';

// Sentry
import * as Sentry from "@sentry/react";



// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
// import './utils/minimal/mapboxgl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';
import './styles/emoji-fix.css';
import './styles/extra-fonts-for-text-editor.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
// ----------------------------------------------------------------------

// scroll bar fix
import './ScrollbarFix.css';

import { BrowserRouter } from 'react-router-dom';
import { HistoryProvider } from './context-state/HistoryContext';

import { HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/minimal/chart';
import SnackbarProvider from './components/minimal/snackbar';
import ScrollToTop from './components/minimal/scroll-to-top';
import { MotionLazyContainer } from './components/minimal/animate';
import { ThemeSettings, SettingsProvider } from './components/minimal/settings';

import { AuthProvider } from './context-state/AuthContext';
import { MobileStyleHandler } from "./components/mobile-style-handler/MobileStyleHandler";
import { SENTRY_DNS } from "./config-global";
import { FaviconHandler } from "./components/FaviconHandler";
import { useEffect } from "react";
import { MarketplaceProvider } from "./context-state/MarketplaceContext.jsx";


// Currently only the prod have Sentry
if (SENTRY_DNS) {
    console.log('Sentry init');
    Sentry.init({
        dsn: SENTRY_DNS,
        integrations: [
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

function App() {
    return (
        <MarketplaceProvider>
            <AuthProvider>
                <HelmetProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                            <BrowserRouter>
                                <HistoryProvider>
                                    <ScrollToTop />
                                    <MotionLazyContainer>
                                        <ThemeProvider>
                                            <MobileStyleHandler />
                                            <ThemeSettings>
                                                <ThemeLocalization>
                                                    <SnackbarProvider>
                                                        <FaviconHandler />
                                                        <StyledChart />
                                                        <Router />
                                                    </SnackbarProvider>
                                                </ThemeLocalization>
                                            </ThemeSettings>
                                        </ThemeProvider>
                                    </MotionLazyContainer>
                                </HistoryProvider>
                            </BrowserRouter>
                        </SettingsProvider>
                    </LocalizationProvider>
                </HelmetProvider>
            </AuthProvider>
        </MarketplaceProvider>
    );
}

export default App
