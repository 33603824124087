import { useParams } from "react-router-dom";
import { useEffect } from "react";
import * as API from "../../../../api/index.js";
import { useAuthContext } from "../../../../context-state/AuthContext.jsx";
import { useEndUsersContext } from "../../../../context-state/EndUsersContext.jsx";
import { LinearLoader } from "../../../../components/linear-loader/LinearLoader.jsx";

export const SingleEndUserGuard = ({ children }) => {

    const { endUserId } = useParams()
    const { getPrimaryAccount } = useAuthContext()
    const { updateEndUsersFullData, getEndUserFullData, setAllAccountsData } = useEndUsersContext()
    const sellerAccount = getPrimaryAccount()
    const endUserData = getEndUserFullData(endUserId)

    const fetchData = async () => {
        try {

            const accounts = []
            const events = []
            
            const [endUserResponse, userEventsResponse, sellerEventsResponse, allAccountsResponse, seasonsTicketsResponse] = await Promise.all([
                API.getEndUserById({ endUserId, accountId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id }),
                API.getUserEventsByEndUser({ endUserId, accountId: sellerAccount._id }),
                API.getSellerEvents(sellerAccount._id),
                API.getSubAccountsByParentId(sellerAccount._id),
                API.getEndUserSeasonTickets({ endUserId, sellerId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id })
            ])

            events.push(...sellerEventsResponse.data)
            accounts.push(...allAccountsResponse.data)

            const userEventsIds = userEventsResponse.data.map(userEvent => userEvent._id)

            const allAccountsData = accounts.map(account => {
                return {
                    accountId: account._id,
                    account,
                    events: events.filter(event => event.account === account._id)
                }
            })

            // This have to be at the end because we first need to fetch all the userEvents
            const eventOrdersResponse = await API.getEventOrdersOfByUserEvents({ marketplaceId: sellerAccount.marketPlace._id, accountId: sellerAccount._id, userEventsIds })

            setAllAccountsData(allAccountsData)
            
            updateEndUsersFullData(endUserId, accounts, events, userEventsResponse.data, endUserResponse.data, eventOrdersResponse.data, seasonsTicketsResponse.data)

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        endUserData ? children : <LinearLoader />
    )
}
