import { useLocales } from "../../../locales";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import FormProvider, {
    RHFUpload,
} from "../../../components/minimal/hook-form";
import React, { useState } from "react";
import * as ExcelJS from "exceljs";
import Iconify from "../../../components/minimal/iconify/index.jsx";
import CustomBreadcrumbs from "../../../components/minimal/custom-breadcrumbs/index.jsx";
import Image from "../../../components/minimal/image/index.jsx";
import { extractSpecificCsvFormatPhoneNumbers } from "./SmsUtils.js";
import parsePhoneNumber from 'libphonenumber-js';
import { useSnackbar } from "notistack";

const SUPORTEDEXCELFILETYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];
const isSupportedFileType = (file) => SUPORTEDEXCELFILETYPES.includes(file.type);

// reads the csv data extract the column with "מספר טלפון" and returns an array of phone numbers
const extractPhoneNumbersFromCsv = (csvData) => {
    const lines = csvData.split('\n');
    const header = lines[0].split(',');
    const indexOfPhoneColumn = header.findIndex(cell => cell.includes('מספר טלפון'));
    const phoneNumbers = [];
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const cells = line.split(',');
        if (cells.length > indexOfPhoneColumn) {
            phoneNumbers.push(cells[indexOfPhoneColumn]);
        }
    }
    return phoneNumbers;
};



export const SmsCampaignFromExcelForm = ({
    excelFile,
    setExcelFile,
    setSmsCampaignPhoneNumbersExcel,
    smsCampaignPhoneNumbersExcel
}) => {

    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();


    const EventSchema = Yup.object().shape({
        excel_file: Yup.mixed().required(translate('dashboard.events.excel_file')),
    });

    const methods = useForm({ resolver: yupResolver(EventSchema), mode: "onChange" });
    const { setValue } = methods
    const [csvPhoneNumbers, setCsvPhoneNumbers] = useState(null); //save the csv data on the local component
    const [isOpenDialog1, setIsOpenDialog1] = useState(false); //save the csv data on the local component
    const [isOpenDialog2, setIsOpenDialog2] = useState(false); //save the csv data on the local component
    const [isOpenDialog3, setIsOpenDialog3] = useState(false); //save the csv data on the local component

    const readExcelFile = async (file) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const data = event.target.result;
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(data);

                const firstSheet = workbook.worksheets[0];

                if (!firstSheet) {
                    setExcelFile(null);
                    throw new Error('No worksheet found in the Excel file.');
                }

                // Convert worksheet to CSV
                let csvData = '';
                firstSheet.eachRow((row) => {
                    row.eachCell((cell, colNumber) => {
                        csvData += cell.value;
                        if (colNumber < row.cellCount) {
                            csvData += ',';
                        }
                    });
                    csvData += '\n';
                });

                const arrayResult = extractSpecificCsvFormatPhoneNumbers(csvData);
                const validIsraeliNumbers = validateIsraeliNumbers(arrayResult);
                const uniquePhoneNumbers = [...new Set(validIsraeliNumbers)]; // remove duplicate numbers
                setCsvPhoneNumbers(uniquePhoneNumbers);
                setSmsCampaignPhoneNumbersExcel(uniquePhoneNumbers);
            } catch (error) {
                console.error('Error processing Excel file:', error);
            }
        };

        reader.readAsArrayBuffer(file);
    }

    const readCsvFile = async (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const csvData = event.target.result;
            const arrayResult = extractPhoneNumbersFromCsv(csvData);
            const validIsraeliNumbers = validateIsraeliNumbers(arrayResult);
            const uniquePhoneNumbers = [...new Set(validIsraeliNumbers)]; // remove duplicate numbers
            setCsvPhoneNumbers(uniquePhoneNumbers);
            setSmsCampaignPhoneNumbersExcel(uniquePhoneNumbers);
        };
        reader.readAsText(file);
    }


    const handleDrop = async (droppedFiles, fieldName) => {
        try {
            const file = droppedFiles[0];
            setExcelFile(file);
            // if file extension is not excel or csv
            if (!isSupportedFileType(file)) {
                setExcelFile(null);
                enqueueSnackbar(translate('dashboard.sms_campaign.file_extension_error'), { variant: 'error' });
                return;
            }
            if (file.type === 'text/csv') {
                readCsvFile(file);
            } else {
                readExcelFile(file);
            }
        } catch (error) {
            console.error('Error handling dropped file:', error);
            // generic error message
            enqueueSnackbar(translate("general_error"), { variant: 'error' });
        }
    };

    const validateIsraeliNumbers = (numbers) => {
        const validNumbers = numbers.map(num => {
            const phoneNumber = parsePhoneNumber(num);
            return (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'IL') ? phoneNumber.number : null;
        }).filter(num => num);
        return validNumbers;
    };


    return (
        <FormProvider width="100%" methods={methods}>
            <Card sx={{ p: 3 }}>
                <Stack spacing={2}>
                    <Stack sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                            {translate('dashboard.sms_campaign.upload_phone_numbers_from_excel_file')}
                        </Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary', ml: 1 }}>
                            {translate('dashboard.sms_campaign.sms_upload_support_files')}
                        </Typography>
                    </Stack>
                    <RHFUpload
                        name=""
                        maxSize={3145728}
                        onDrop={(data) => {
                            handleDrop(data, 'excel_file')
                        }}
                        onDelete={() => setValue('excel_file', null)}
                        accept={{
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                            'application/vnd.ms-excel': [],
                            'text/csv': [],
                        }}
                        disabled={!!excelFile} // Disable the upload component if excelFile is set
                    />
                    {
                        excelFile && <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1">{excelFile.name}</Typography>
                            {/* phonenumbers */}
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                {csvPhoneNumbers?.length} {translate('dashboard.sms_campaign.phones')}
                            </Typography>
                            <IconButton sx={{ color: 'error.main' }} onClick={() => {
                                setSmsCampaignPhoneNumbersExcel([]) // father component
                                setExcelFile(null)
                                setCsvPhoneNumbers(null)
                            }} // child component
                            >
                                <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                        </Stack>
                    }
                </Stack>
                <Typography variant="h6" sx={{ p: 2, color: 'text.secondary' }}>
                    {translate('dashboard.sms_campaign.sms_excel_upload_example')}
                </Typography>
                <Stack sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                    <CustomBreadcrumbs
                        links={[{ name: '' }]}
                        action={
                            <Button
                                variant="outlined"
                                onClick={() => setIsOpenDialog1(true)}
                            >
                                {translate('dashboard.sms_campaign.sms_excel_upload_first_file')}
                            </Button>
                        }
                    />

                    <Dialog fullWidth maxWidth="md" open={isOpenDialog1}
                        onClose={() => setIsOpenDialog1(false)}>
                        <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
                            <Image
                                visibleByDefault
                                alt="event-image"
                                src={"https://d2iyod2z8hftl8.cloudfront.net/local_1712944375828_Screenshot%202024-04-11%20at%2020.14.23.png"}
                                sx={{ maxWidth: 1000 }}
                            />
                            <Button onClick={() => setIsOpenDialog1(false)} color="primary">
                                סגור
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <CustomBreadcrumbs
                        links={[{ name: '' }]}
                        action={
                            <Button
                                variant="outlined"
                                onClick={() => setIsOpenDialog2(true)}
                            >
                                {translate('dashboard.sms_campaign.sms_excel_upload_second_file')}
                            </Button>
                        }
                    />

                    <Dialog fullWidth maxWidth="md" open={isOpenDialog2} onClose={() => setIsOpenDialog2(false)}>
                        <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
                            <Image
                                visibleByDefault
                                alt="event-image"
                                src={"https://d2iyod2z8hftl8.cloudfront.net/local_1713174110552_Screenshot%202024-04-15%20at%2012.37.03.png"}
                                sx={{ maxWidth: 1000 }}
                            />
                            <Button onClick={() => setIsOpenDialog2(false)} color="primary">
                                סגור
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <CustomBreadcrumbs
                        links={[{ name: '' }]}
                        action={
                            <Button
                                variant="outlined"
                                onClick={() => setIsOpenDialog3(true)}
                            >
                                {translate('dashboard.sms_campaign.sms_excel_upload_third_file')}
                            </Button>
                        }
                    />

                    <Dialog fullWidth maxWidth="md" open={isOpenDialog3}
                        onClose={() => setIsOpenDialog3(false)}>
                        <DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
                            <Image
                                visibleByDefault
                                alt="event-image"
                                src={"https://d2iyod2z8hftl8.cloudfront.net/local_1713173889126_Screenshot%202024-04-15%20at%2012.36.20.png"}
                                sx={{ maxWidth: 1000 }}
                            />
                            <Button onClick={() => setIsOpenDialog3(false)} color="primary">
                                סגור
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Stack>
            </Card>
        </FormProvider>
    )
}
    ;
