import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const EventContext = createContext(null);

EventProvider.propTypes = {
    children: PropTypes.node,
};

export function EventProvider({ children }) {

    const [event, setEvent] = useState(null)
    const [account, setAccount] = useState(null)
    const [coupon, setCoupon] = useState(null)
    const [seasonTicket, setSeasonTicket] = useState(null)
    const [initialUserEventDetails, setInitialUserEventDetails] = useState(null)
    const [tickets, setTickets] = useState([])
    const [appliedCouponQs, setAppliedCouponQs] = useState(null)
    const [appliedHrcQS, setAppliedHrcQs] = useState(null)


    return (
        <EventContext.Provider
            value={{
                eventPageContext: {
                    event,
                    setEvent,
                    account,
                    setAccount,
                    tickets,
                    setTickets,
                    coupon,
                    setCoupon,
                    initialUserEventDetails,
                    setInitialUserEventDetails,
                    seasonTicket,
                    setSeasonTicket,
                    appliedCouponQs,
                    setAppliedCouponQs,
                    appliedHrcQS,
                    setAppliedHrcQs,
                },
            }}
        >
            {children}
        </EventContext.Provider>
    );
}

export const useEventContext = () => {
    const context = useContext(EventContext);

    if (!context) throw new Error('useEventContext context must be use inside EventProvider');

    return context;
};
