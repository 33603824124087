import { useSnackbar } from "../../../components/minimal/snackbar";
import { useLocales } from "../../../locales";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, Button, Card, IconButton, Stack, Typography } from "@mui/material";
import FormProvider, { RHFTextField } from "../../../components/minimal/hook-form";
import Iconify from "../../../components/minimal/iconify";
import { useEffect } from "react";
import { m } from "framer-motion";

export const SmsCampaignManuallyForm = ({ setSmsCampaignPhoneNumbersManually, smsCampaignPhoneNumbersManually }) => {

    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const israeliNumberRegex = /^(?:\+972|0|972)5\d{7,10}$/;


    const methods = useForm({ resolver: yupResolver(), smsCampaignPhoneNumbersManually });
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "phoneNumbers"
    });

    useEffect(() => {
        if (smsCampaignPhoneNumbersManually && smsCampaignPhoneNumbersManually.length) {
            smsCampaignPhoneNumbersManually.forEach(number => {
                append(number);
            });
        }
    }, []);

    const handleAppend = () => {
        const { phoneNumbers } = methods.getValues();

        let indexToSetErrorFor

        const hasEmptyFields = phoneNumbers.some((num, i) => {
            num = num.trim()
            if (num === '') {
                indexToSetErrorFor = i
                return true
            }
        })

        if (hasEmptyFields) {
            methods.setError(`phoneNumbers[${indexToSetErrorFor}]`, { message: "נא למלא תא זה" })
            enqueueSnackbar(translate('dashboard.sms_campaign.sms_alert_please_provide_all_phone_numbers'), { variant: 'error' });
            return;
        }

        const uniquePhoneNumbers = Array.from(new Set(phoneNumbers));
        if (uniquePhoneNumbers.length !== phoneNumbers.length) {

            let indexToSetErrorForOne
            let indexToSetErrorForTwo

            phoneNumbers.forEach((number, index) => {
                phoneNumbers.forEach((num, i) => {
                    if (num === number && i !== index) {
                        indexToSetErrorForOne = i
                        indexToSetErrorForTwo = index
                    }
                })
            })
            methods.setError(`phoneNumbers[${indexToSetErrorForOne}]`, { message: "מספרים זהים" })
            methods.setError(`phoneNumbers[${indexToSetErrorForTwo}]`, { message: "מספרים זהים" })

            enqueueSnackbar(translate('dashboard.sms_campaign.sms_alert_duplicate_phone_numbers_are_not_allowed'), { variant: 'error' });
            return;
        }

        const invalidNumbers = phoneNumbers.filter(num => !num.match(israeliNumberRegex));
        if (invalidNumbers.length > 0) {
            enqueueSnackbar(translate('dashboard.sms_campaign.sms_alert_invalid_israeli_phone_numbers_provided'), { variant: 'error' });
            return;
        }


        // first time click on new number.
        if (!phoneNumbers.length) {
            append('');
            return
        }

        append(''); // Append new phone number if no duplicates
    };

    const approvePhoneNumbers = () => {
        const { phoneNumbers } = methods.getValues();
        setSmsCampaignPhoneNumbersManually([...phoneNumbers])
    }

    function getFullNumber(data) {
        let fullNumber = '';

        for (let key in data) {
            if (key !== 'id') { // Skip the 'id' property
                fullNumber += data[key];
            }
        }
        return fullNumber;
    }

    function removeSelectedNumbers(numberToRemove) {
        const filteredNumbers = smsCampaignPhoneNumbersManually.filter(num => num !== numberToRemove)
        setSmsCampaignPhoneNumbersManually(filteredNumbers)
    }

    useEffect(() => {
        methods.clearErrors()
    }, [methods.watch()])

    return (
        <Card sx={{ p: 3, marginBottom: 2, minWidth: '300px' }}>
            <FormProvider width="100%" methods={methods}>
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                    {translate('dashboard.sms_campaign.upload_phones_numbers_manually')}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {fields.map((item, index) => (
                        <m.div
                            key={item.id}
                            initial={{ opacity: 0, y: 20 }} // Initial state
                            animate={{ opacity: 1, y: 0 }}   // Animation state
                            exit={{ opacity: 0, y: -20 }}    // Exit state
                            transition={{ duration: 0.3 }}   // Transition duration
                        >
                            <Stack sx={{ p: 1.6, display: "flex", flexDirection: "row", alignItems: "center" }} key={item.id}>
                                <RHFTextField
                                    size="small"
                                    name={`phoneNumbers[${index}]`}
                                    label={`מספר טלפון  ${index + 1}`}
                                    defaultValue={item.value} // Populate with existing values
                                />
                                <IconButton
                                    sx={{
                                        borderRadius: '50%',
                                        width: '48px',
                                        height: '48px',
                                        alignSelf: "center",
                                        marginLeft: "8px"
                                    }}
                                    onClick={() => {
                                        const numberToRemove = getFullNumber(fields[index])
                                        removeSelectedNumbers(numberToRemove)
                                        remove(index)
                                    }
                                    }>
                                    <Iconify sx={{ width: "20px", height: "20px" }} icon="mdi:delete" />
                                </IconButton>
                            </Stack>
                        </m.div>
                    ))}

                </Box>
                <Stack sx={{ gap: 2, mt: '35px' }}>
                    <Button onClick={handleAppend}>
                        <Iconify icon="eva:plus-fill" />
                        הוספת מספר
                    </Button>
                    <Button disabled={!fields.length} onClick={approvePhoneNumbers} variant="contained">
                        אישור
                    </Button>
                </Stack>
            </FormProvider>
        </Card>
    )
}
