import PropTypes from 'prop-types';
import '../../../utils/minimal/highlight';
import ReactQuill from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
//
import { StyledEditor } from './styles';
import EditorToolbar, { formats, FONTS } from './EditorToolbar';

// emoji
import quillEmoji from 'quill-emoji';
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

ReactQuill.Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);


// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
};

const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it

ReactQuill.Quill.register(Font, true);
Font.whitelist = FONTS;
ReactQuill.Quill.register('modules/imageResize', ImageResize);

// ReactQuill.format('direction', 'rtl')
const Link = ReactQuill.Quill.import('formats/link');
Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url;
}


export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...other
}) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: ReactQuill.Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} />

        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
